<template>
  <v-dialog
    v-bind="$attr"
    fullscreen
    :overlay="false"
    max-width="300px"
    transition="dialog-transition"
  >
    <v-card>
      <v-card-text>
        <v-card-title primary-title class="px-0"> Filtros: </v-card-title>
        <v-form @submit.prevent>
          <div class="">
            <p class="mb-2">Nome ou E-mail</p>
            <v-text-field
              v-model="filters.emailOrNameLike"
              variant="outlined"
              density="compact"
            ></v-text-field>
          </div>
          <!-- <div>
            <p class="mb-2">Idade entre</p>
            <v-text-field
              v-model="filters.nameOrEmail"
              variant="outlined"
              density="compact"
            ></v-text-field>
          </div> -->
          <div class="mb-3">
            <p class="mb-2">Data de criação</p>
            <div class="d-flex align-center py-0">
              <span>De</span>
              <v-spacer></v-spacer>
              <v-text-field
                v-model="filters.dateCreateStart"
                variant="outlined"
                density="compact"
                type="date"
                class="d-flex"
              ></v-text-field>
            </div>
            <div class="d-flex align-center my-2">
              <div>A</div>
              <v-spacer></v-spacer>
              <v-text-field
                v-model="filters.dateCreateEnd"
                variant="outlined"
                density="compact"
                type="date"
                class="d-flex ml-3"
              ></v-text-field>
            </div>
          </div>
          <div class="">
            <p>Situação</p>
            <v-radio-group v-model="filters.active">
              <v-radio label="Ativo" :value="1"></v-radio>
              <v-radio label="Inativo" :value="0"></v-radio>
            </v-radio-group>
          </div>

          <div>
            <v-btn block color="primary" @click="onFilter">Filtrar</v-btn>
            <v-btn block color="white" class="my-2" @click="onReset"
              >Resetar</v-btn
            >
            <v-btn block color="white" class="my-2" @click="onClose"
              >Cancelar</v-btn
            >
          </div>
        </v-form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { ref } from "vue";

export default {
  name: "UserFiltersModal",
  emits: ["OnFilter", "onClose"],
  setup(props, ctx) {
    const filters = ref({
      emailOrNameLike: "",
    });

    function onClose() {
      ctx.emit("onClose");
    }

    function onReset() {
      filters.value = {};
    }
    function onFilter() {
      ctx.emit("onFilter", { ...filters.value });
      onClose();
    }

    return { filters, onFilter, onClose, onReset };
  },
};
</script>
