import securityService from "../../services/securityService";
import { config } from "../../config/AppConfig";
import { ADMIN } from "../../enums/perfilEnum";

const state = {
  user: null,
};

const getters = {
  userLogged: (state) => {
    return state.user && state.user.token;
  },
  user: (state) => {
    return state.user;
  },
};

const mutations = {
  SET_USER: (state, user) => {
    state.user = user;
    if (user) {
      window.sessionStorage.setItem(config.storeTokenKey, user.token);
    }
  },
};

const actions = {
  async login({ commit }, credentials) {
    const resp = await securityService.login(credentials);
    if (resp.data.data.accessLevel == ADMIN) {
      await commit("SET_USER", resp.data.data);
    } else {
      throw Error("Usuário não autorizado");
    }
  },

  async refresh({ commit }) {
    const token = securityService.getStoredToken();
    if (!token) {
      throw new Error("Sua sessão foi expirada.");
    }
    const resp = await securityService.reconnect(token);
    await commit("SET_USER", resp.data.data);
  },

  async logout({ commit }) {
    const token = securityService.getStoredToken();
    console.log(token);
    return securityService.logout(token).then(() => {
      commit("SET_USER", null);
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
