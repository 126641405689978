<template>
  <v-dialog
    v-bind="$attr"
    fullscreen
    persistent
    :overlay="false"
    max-width="500px"
    transition="dialog-transition"
  >
    <v-card>
      <v-card-text>
        <v-card-title primary-title class="pl-0">
          {{
            userDto && userDto.id ? "Atualizar usuário" : " Cadastrar usuário"
          }}
        </v-card-title>
        <v-divider></v-divider>
        <v-form class="my-3" @submit.prevent ref="userForm">
          <v-text-field
            v-model="userDto.name"
            :rules="rules.name"
            label="Nome"
            clearable
          ></v-text-field>
          <v-text-field
            v-model="userDto.dateBirth"
            :rules="rules.datebirth"
            label="Data de Nascimento"
            type="date"
            clearable
          ></v-text-field>
          <v-text-field
            type="email"
            v-model="userDto.email"
            :rules="rules.email"
            label="E-mail"
            clearable
          ></v-text-field>
          <v-text-field
            v-if="!userDto.id"
            type="password"
            v-model="userDto.password"
            :rules="rules.password"
            label="Senha"
            clearable
          ></v-text-field>
          <v-select
            v-model="userDto.subscriptionPlanId"
            :items="plans"
            label="Plano"
            item-value="id"
            item-title="name"
            :return-object="false"
            :rules="rules.plan"
          ></v-select>
          <v-select
            v-model="userDto.perfilId"
            :items="perfis"
            label="Perfil de Acesso"
            item-value="id"
            item-title="name"
            :return-object="false"
            :rules="rules.perfil"
          ></v-select>
          <v-switch
            v-model="userDto.active"
            color="primary"
            label="Ativo"
            :value="1"
            hide-details
          ></v-switch>
        </v-form>
        <v-spacer></v-spacer>
        <div class="d-flex align-center justify-end my-3">
          <v-btn prepend-icon="mdi-close" color="default" @click="close">
            Cancelar</v-btn
          >
          <v-btn
            prepend-icon="mdi-check"
            color="primary"
            class="ml-3"
            @click="save"
            :loading="saveLoading"
          >
            Salvar</v-btn
          >
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
/* eslint-disable */

import { onMounted, ref, toRefs, watch } from "vue";
import userService from "../../services/userService";
import { useStore } from "vuex";
import planService from "../../services/planService";
import perfilService from "../../services/perfilService";

export default {
  name: "CreateEditUserModal",
  emits: ["onSavedOrUpdated", "onClose"],
  props: {
    user: { type: Object, default: () => ({}) },
  },
  setup(props, ctx) {
    const store = useStore();

    const { user } = toRefs(props);
    const saveLoading = ref(false);
    const userDto = ref({
      active: 1,
      ...user.value,
    });
    const plans = ref([]);
    const perfis = ref([]);
    const userForm = ref(null);

    const validations = {
      required: (value) => !!value || "Campo obrigatório",
      password: (value) =>
        (value && value.lenght >= 8) ||
        "A senha deve ter no mínimo 8 caracteres",
    };

    const rules = {
      name: [validations.required],
      email: [validations.required],
      datebirth: [validations.required],
      password: [validations.required],
      plan: [validations.required],
      perfil: [validations.required],
    };

    watch(user, (newValue) => {
      userDto.value = { ...newValue };
    });

    onMounted(() => {
      loadPlans();
      loadPerfis();
    });

    function reset() {
      userDto.value = {};
    }

    async function save() {
      try {
        const { valid } = await userForm.value.validate();
        if (!valid) {
          return;
        }
        saveLoading.value = true;
        const resp = await userService.save(userDto.value);
        if (resp) {
          store.dispatch("snackbar/success", "Dados salvos com sucesso");
          ctx.emit("onSavedOrUpdated");
          close();
        }
      } catch (error) {
        store.dispatch("snackbar/error", error);
      } finally {
        saveLoading.value = false;
      }
    }

    function close() {
      reset();
      ctx.emit("onClose");
    }

    async function loadPlans() {
      try {
        const resp = await planService.list(0, 100, "name:asc");
        if (resp.data.content) {
          plans.value = resp.data.content.map(({ id, name }) => ({
            id,
            name,
          }));
        }
      } catch (error) {
        console.log(error);
      }
    }

    async function loadPerfis() {
      try {
        const resp = await perfilService.list(0, 100, "name:asc");
        if (resp.data.content) {
          perfis.value = resp.data.content.map(({ id, name }) => ({
            id,
            name,
          }));
        }
      } catch (error) {
        console.log(error);
      }
    }

    return {
      userForm,
      plans,
      perfis,
      userDto,
      rules,
      saveLoading,
      save,
      close,
    };
  },
};
</script>
