<template>
  <v-menu v-bind="$attrs" :close-on-content-click="false" location="end">
    <template v-slot:activator="{ props }">
      <v-btn color="white" v-bind="props" prepend-icon="mdi-sort" class="mx-2">
        Ordenar por
      </v-btn>
    </template>
    <v-card min-width="200">
      <v-list>
        <v-list-item title="Ordenação"> </v-list-item>
      </v-list>
      <v-divider></v-divider>
      <v-radio-group v-model="orderBy">
        <v-list>
          <v-list-item title="Data de Criação">
            <v-radio label="Ascendente" value="dateCreate:asc"></v-radio>
            <v-radio label="Decrescente" value="dateCreate:desc"></v-radio>
          </v-list-item>
          <v-divider></v-divider>
          <v-list-item title="Nome">
            <v-radio label="A-Z" value="name:asc"></v-radio>
            <v-radio label="Z-A" value="name:desc"></v-radio>
          </v-list-item>
          <!-- <v-divider></v-divider>
          <v-list-item title="Último acesso">
            <v-radio label="Ascendente" value="lastAcess:asc"></v-radio>
            <v-radio label="Decrescente" value="lastAcess:desc"></v-radio>
          </v-list-item> -->
        </v-list>
      </v-radio-group>
      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn variant="text" @click="onClose"> Cancelar </v-btn>
      </v-card-actions>
    </v-card>
  </v-menu>
</template>
<script>
import { ref, watch } from "vue";
export default {
  name: "OrderByGamesMenu",
  emits: ["onClose", "onUpdateOrderBY"],
  setup(props, ctx) {
    const orderBy = ref(null);

    function onClose() {
      ctx.emit("onClose");
    }

    watch(orderBy, () => ctx.emit("onUpdateOrderBY", orderBy.value));

    return {
      orderBy,
      onClose,
    };
  },
};
</script>
