import http from "../infra/config/httpClient";
const urlBase = "/api/v2";

/**
 *
 * @param {int} page
 * @param {int} perPage
 * @param {Object} filters
 * @param {String} orderBY name:asc|etc:desc
 * @returns
 */
const list = (page, perPage, filters, orderBy) => {
  return http.get(`${urlBase}/users`, {
    params: {
      page,
      size: perPage,
      orderBy,
      ...filters,
    },
  });
};

const save = (payload) => {
  return http.post(`${urlBase}/admin/users`, payload);
};

export default { list, save };
