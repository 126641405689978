import http from "../infra/config/httpClient";

const list = (page, size, orderBy) => {
  return http.get("/api/v2/subscriptions-plans", {
    params: {
      page,
      size,
      orderBy,
    },
  });
};

export default {
  list,
};
