const state = {
  open: false,
  message: null,
  type: "success", // success, error, info, warning
};

const getters = {
  open: (state) => state.open,
  message: (state) => state.message,
  type: (state) => state.type,
};

const mutations = {
  SET_OPEN: (state, open) => {
    state.open = open;
  },
  SET_MESSAGE: (state, message) => {
    state.message = message;
  },
  SET_TYPE: (state, type) => {
    state.type = type;
  },
};
const actions = {
  success({ dispatch }, message) {
    dispatch("open", message, "success");
  },
  error({ dispatch }, message) {
    dispatch("open", message, "error");
  },

  open({ commit, dispatch }, message, type) {
    commit("SET_OPEN", true);
    commit("SET_MESSAGE", message);
    commit("SET_TYPE", type);
    setTimeout(() => {
      dispatch("close");
    }, 3000);
  },

  close({ commit }) {
    commit("SET_OPEN", false);
    commit("SET_MESSAGE", null);
    commit("SET_TYPE", null);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
