import Home from "../views/HomeView.vue";
import UserListView from "../views/users/UserListView.vue";
import GameListView from "../views/masterRoom/games/GameListView.vue";

export const routes = [
  {
    path: "/",
    name: "home",
    component: Home,
    meta: { requiresAuth: true },
  },
  {
    path: "/login",
    name: "login",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/LoginView.vue"),
  },
  {
    path: "/users",
    name: "users",
    component: UserListView,
    meta: { requiresAuth: true },
  },
  {
    path: "/master-room/games",
    name: "masterRoom.games",
    component: GameListView,
    meta: { requiresAuth: true },
  },
];
