<template>
  <v-layout class="rounded rounded-md">
    <DrawerMenu v-if="user" v-model="drawer" />
    <v-app-bar class="px-3" v-if="user">
      <template v-slot:prepend v-if="$vuetify.display.mdAndDown">
        <v-app-bar-nav-icon
          variant="text"
          @click.stop="drawer = !drawer"
        ></v-app-bar-nav-icon>
      </template>
      <v-app-bar-title>Olá, {{ user.name }}</v-app-bar-title>
    </v-app-bar>
    <v-main class="content">
      <SnackbarAlert />
      <router-view />
    </v-main>
  </v-layout>
</template>
<script setup>
import { computed, onBeforeMount, ref } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import DrawerMenu from "./views/layout/DrawerMenu.vue";
import SnackbarAlert from "./components/SnackbarAlert.vue";

const router = useRouter();

const store = useStore();
const user = computed(() => store.getters["auth/user"]);
const drawer = ref(true);

onBeforeMount(async () => {
  try {
    if (!user.value) {
      await store.dispatch("auth/refresh");
    }
  } catch (error) {
    console.log(error);
    router.push({ name: "login" });
  }
});
</script>
<style lang="scss" scoped>
.content {
  background-color: #ededed;
  min-height: 100vh;
}
</style>
