<template lang="">
  <v-snackbar multi-line v-model="open" :color="type">
    <p>{{ message }}</p>
    <template v-slot:actions>
      <v-btn variant="text" @click="close"> Fechar </v-btn>
    </template>
  </v-snackbar>
</template>
<script>
import { computed } from "vue";
import { useStore } from "vuex";

export default {
  name: "SnackbarAlert",
  setup() {
    const store = useStore();
    const open = computed(() => store.getters["snackbar/open"]);
    const message = computed(() => store.getters["snackbar/message"]);
    const type = computed(() => store.getters["snackbar/type"]);

    function close() {
      store.dispatch("snackbar/close");
    }
    return {
      open,
      message,
      type,
      close,
    };
  },
};
</script>
