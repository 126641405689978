import { createRouter, createWebHashHistory } from "vue-router";
import { routes } from "./routes";
import securityService from "../services/securityService";
import { useStore } from "vuex";
import { ADMIN } from "../enums/perfilEnum";

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    const store = useStore();
    const user = store.getters["auth/user"] || null;
    console.log(user);

    if (!securityService.isLogged()) {
      // next({
      //   path: "/login",
      //   query: { redirect: to.fullPath },
      // });
      next("/login").catch(() => {});
    } else {
      if (user && user.accessLevel != ADMIN) {
        store.dispatch("auth/logout");
        next("/login").catch(() => {});
      }
      next();
    }
  } else {
    next();
  }
});

export default router;
