<template>
  <div class="px-3 py-3" style="">
    <header class="d-flex align-center justify-space-between mb-3">
      <h1>Usuários ({{ !paginator ? 0 : paginator.totalElements }})</h1>
      <div class="d-flex align-center justify-end">
        <v-menu v-model="menu" :close-on-content-click="false" location="end">
          <template v-slot:activator="{ props }">
            <v-btn
              color="white"
              v-bind="props"
              prepend-icon="mdi-sort"
              class="mx-2"
            >
              Ordenar por
            </v-btn>
          </template>

          <v-card min-width="200">
            <v-list>
              <v-list-item title="Ordenação"> </v-list-item>
            </v-list>
            <v-divider></v-divider>
            <v-radio-group v-model="orderBy">
              <v-list>
                <v-list-item title="Data de Criação">
                  <v-radio label="Ascendente" value="dateCreate:asc"></v-radio>
                  <v-radio
                    label="Decrescente"
                    value="dateCreate:desc"
                  ></v-radio>
                </v-list-item>
                <v-divider></v-divider>
                <v-list-item title="Nome">
                  <v-radio label="A-Z" value="name:asc"></v-radio>
                  <v-radio label="Z-A" value="name:desc"></v-radio>
                </v-list-item>
                <v-divider></v-divider>
                <v-list-item title="Último acesso">
                  <v-radio label="Ascendente" value="lastAcess:asc"></v-radio>
                  <v-radio label="Decrescente" value="lastAcess:desc"></v-radio>
                </v-list-item>
              </v-list>
            </v-radio-group>
            <v-divider></v-divider>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn variant="text" @click="menu = false"> Cancelar </v-btn>
            </v-card-actions>
          </v-card>
        </v-menu>
        <v-btn
          class="mr-2"
          prepend-icon="mdi-filter"
          color="white"
          @click="() => (showUserFiltersModal = true)"
          >Filtrar</v-btn
        >
        <v-btn
          prepend-icon="mdi-plus"
          color="primary"
          @click="() => (showCreateEditModal = true)"
          >Criar Usuário</v-btn
        >
      </div>
    </header>
    <v-card>
      <v-card-text>
        <v-data-table
          :headers="headers"
          :items="users"
          :items-per-page="perPage"
          :return-object="true"
          @update:itemsPerPage="(p) => (perPage = p)"
          :page="page"
          items-per-page-text="Itens por página"
        >
          <template v-slot:item="{ item }">
            <tr>
              <td>{{ item.name }}</td>
              <td>{{ item.email }}</td>
              <td>{{ item.dateBirth }}</td>
              <td>{{ item.dateCreate }}</td>
              <td>{{ item.lastAcess }}</td>
              <td>{{ item.subscriptionPlan }}</td>
              <td>{{ item.active }}</td>
              <td>
                <v-menu>
                  <template v-slot:activator="{ props }">
                    <v-btn
                      icon="mdi-tune"
                      v-bind="props"
                      variant="plain"
                    ></v-btn>
                  </template>

                  <v-list>
                    <v-list-item link title="Editar" @click="edit(item)">
                    </v-list-item>
                  </v-list>
                </v-menu>
              </td>
            </tr>
          </template>

          <template v-slot:bottom v-if="paginator">
            <div class="text-center pt-2">
              <v-pagination
                v-model="page"
                :length="paginator.totalPages"
              ></v-pagination>
            </div>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </div>
  <CreateEditUserModal
    v-model="showCreateEditModal"
    :user="userSelected"
    @onSavedOrUpdated="loadUsers"
    @onClose="closeCadEditModal"
  />
  <UserFiltersModal
    v-model="showUserFiltersModal"
    @OnFilter="search"
    @onClose="showUserFiltersModal = false"
  />
</template>

<script>
import { computed, nextTick, onBeforeMount, ref, watch } from "vue";
import userService from "../../services/userService";
import { differenceInYears, formatDate } from "date-fns";
import { format } from "date-fns/format";
import CreateEditUserModal from "./CreateEditUserModal.vue";
import UserFiltersModal from "./UserFiltersModal.vue";

export default {
  name: "UserListView",
  components: {
    CreateEditUserModal,
    UserFiltersModal,
  },
  setup() {
    const headers = ref([
      {
        title: "Nome",
        align: "start",
        key: "name",
      },
      {
        title: "E-email",
        align: "start",
        key: "email",
      },
      {
        title: "Idade",
        align: "start",
        key: "dateBirth",
      },
      {
        title: "Data Criação",
        align: "start",
        key: "dateCreate",
      },
      {
        title: "Último Acesso",
        align: "start",
        key: "lastAcess",
      },
      {
        title: "Plano",
        align: "start",
        key: "subscriptionPlan",
      },
      {
        title: "Situação",
        align: "start",
        key: "active",
      },
      {
        title: "Ações",
        align: "center",
        key: "actions",
      },
    ]);
    const users = ref([]);
    const page = ref(1);
    const perPage = ref(10);
    const filters = ref({});
    const orderBy = ref("name:asc");
    const paginator = ref(null);
    const showCreateEditModal = ref(false);
    const userSelected = ref(null);
    const showUserFiltersModal = ref(false);
    const menu = ref(false);

    const usersFormated = computed(() => {
      return users.value.map((u) => {
        const now = new Date();
        const dateBirth = new Date(u.dateBirth);
        const age = differenceInYears(now, dateBirth);

        const strDate = u.dateCreate.replace("[GMT-03:00]", "");
        const dateCreate = format(new Date(strDate), "dd/MM/yyyy H:m:s");
        const subscriptionPlan = u.subscriptionPlan.name;

        let lastAcess = null;
        if (u.lastAcess) {
          const strLastAcess = u.lastAcess.replace("[GMT-03:00]", "");
          lastAcess = format(new Date(strLastAcess), "dd/MM/yyyy H:m:s");
        }

        const active = u.active ? "Ativo" : "Inativo";

        return {
          ...u,
          dateBirth: age,
          dateCreate,
          lastAcess,
          subscriptionPlan,
          active,
        };
      });
    });

    onBeforeMount(() => {
      loadUsers();
    });

    watch(perPage, () => {
      loadUsers();
    });

    watch(orderBy, () => {
      loadUsers();
    });

    watch(page, () => {
      loadUsers();
    });

    async function loadUsers() {
      try {
        const pageNumber = page.value && page.value >= 1 ? page.value - 1 : 0;
        console.log("filtros: ", filters.value);
        const resp = await userService.list(
          pageNumber,
          perPage.value,
          filters.value,
          orderBy.value
        );
        users.value = resp.data.content || [];
        paginator.value = resp.data || null;
      } catch (error) {
        console.log(error);
      }
    }

    function edit(user) {
      userSelected.value = null;
      nextTick(() => {
        const userOriginal = users.value.find((u) => u.id == user.id);
        userOriginal.dateBirth = formatDate(
          new Date(userOriginal.dateBirth),
          "yyyy-MM-dd"
        );
        userSelected.value = {
          ...userOriginal,
          perfilId: userOriginal.accessLevel,
          subscriptionPlanId: userOriginal.subscriptionPlan.id,
        };
        showCreateEditModal.value = true;
      });
    }

    function closeCadEditModal() {
      userSelected.value = null;
      showCreateEditModal.value = false;
    }

    async function search(f) {
      filters.value = { ...f };
      // page.value = 0;
      loadUsers();
    }

    return {
      headers,
      users: usersFormated,
      page,
      paginator,
      perPage,
      showCreateEditModal,
      userSelected,
      showUserFiltersModal,
      menu,
      orderBy,
      edit,
      closeCadEditModal,
      search,
      loadUsers,
    };
  },
};
</script>
