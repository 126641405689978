// src/axios.js
import axios from "axios";
import securityService from "../../services/securityService";

// Crie uma instância do Axios
const axiosInstance = axios.create({
  baseURL: process.env.VUE_APP_NIO_API_V2_URL_BASE, // Substitua pela URL da sua API
  timeout: process.env.VUE_APP_REQ_TIMEOUT || 3000, // Tempo limite de 10 segundos
  headers: {
    "Content-Type": "application/json",
    // Adicione outros cabeçalhos aqui se necessário
  },
});
// Adicionar interceptores se necessário
axiosInstance.interceptors.request.use(
  (config) => {
    const token = securityService.getStoredToken();
    if (token) {
      config.headers.Authorization = `${token}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    // Lidar com erros de resposta
    if (error.response.data) {
      if (error.response.data.messages) {
        return Promise.reject(
          error.response.data.messages.map((p) => p.message)
        );
      }
      return Promise.reject(error.response.data.error);
    }
    return Promise.reject(error);
  }
);

export default axiosInstance;
